<template>
    <form method="post" class="grid grid-cols-12 gap-6 mt-4" :class="{ 'disabled-form': loading }">
        <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
            <Preloader />
        </div>

        <div class="relative col-span-4">
            <label for="form-title" class="form-label">
                Название
                <sup v-if="v$.form.title.required" class="text-theme-6">*</sup>
            </label>
            <label v-if="v$.form.title.$errors.length" for="form-title" class="form-input-text-error">
                {{ getOverrideMessage(v$.form.title.$errors[0].$message) }}
            </label>
            <input
                id="form-title"
                v-model="form.title"
                type="text"
                class="form-control w-full"
                :class="{ 'form-input-error': v$.form.title.$errors.length }"
                placeholder=""
                autocomplete="off"
            />
        </div>

        <div class="col-span-4">
            <label for="form-description" class="form-label">Описание</label>
            <input
                id="form-description"
                v-model="form.description"
                type="text"
                class="form-control w-full"
                placeholder=""
                autocomplete="off"
            />
        </div>

        <div class="relative col-span-4">
            <label for="form-color" class="form-label">
                Цвет
                <sup v-if="v$.form.color.required" class="text-theme-6">*</sup>
            </label>
            <label v-if="v$.form.color.$errors.length" for="form-color" class="form-image-text-error">
                {{ getOverrideMessage(v$.form.color.$errors[0].$message) }}
            </label>
            <input
                id="form-color"
                v-model="form.color"
                type="color"
                class="form-control color-field w-full p-1"
                :class="{ 'form-input-error': v$.form.color.$errors.length }"
                autocomplete="off"
            />
        </div>

        <div class="col-span-12 flex items-center justify-end mt-5">
            <router-link :to="{ name: 'offers' }" class="btn btn-secondary w-24 mb-2 mr-2">Отмена</router-link>
            <button type="submit" class="btn btn-primary mb-2 mr-2" @click.prevent="save(false)">
                Сохранить и выйти
            </button>
            <button type="submit" class="btn btn-primary mb-2" @click.prevent="save(true)">Сохранить</button>
        </div>
    </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { errorResponse, formPreparation } from '@/mixins/form/form-mixin';
import Preloader from '@/components/preloader/Main';
import { helper as $h } from '@/utils/helper';

export default {
    name: 'Edit',
    components: { Preloader },
    mixins: [errorResponse, formPreparation],
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            loading: false,
            form: {
                title: null,
                color: null,
                description: null,
            },
            seo: [],
            seoHeaders: [
                { field: 'code', label: 'Название параметра' },
                { field: 'value', label: 'Значение' },
            ],
        };
    },
    validations() {
        return {
            form: {
                title: { required },
                color: { required },
            },
        };
    },
    created() {
        this.$store.commit('main/setPageHeader', 'Спецпредложения / Редактирование');
        this.fetchData();
    },
    methods: {
        async fetchData() {
            this.loading = true;
            this.axios
                .get(`/offers/${this.$route.params.id}/edit`)
                .then(({ data }) => {
                    this.form.title = data.title;
                    this.form.color = data.color;
                    this.form.description = data.description;
                    this.loading = false;
                })
                .catch((ex) => {
                    console.log('cant fetch single data: ' + ex);
                    this.loading = false;
                    // this.$router.push('/error-page');
                });
        },
        async save(flag) {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.$notify({ text: 'Заполните все поля правильно', type: 'error' });
                return;
            }

            this.loading = true;
            this.form.seo = this.seo;
            const formData = this.formPreparation();

            this.axios
                .post(`/offers/${this.$route.params.id}`, formData, {
                    params: {
                        _method: 'patch',
                    },
                })
                .then(() => {
                    this.$notify('Спецпредложение обновлено');

                    if (flag) {
                        this.fetchData();
                    } else {
                        this.$router.push({ name: 'offers' });
                    }
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                });
        },
        getOverrideMessage(message) {
            return $h.getOverrideMessage(message);
        },
    },
};
</script>
